<template lang="pug">
  div(v-if="latestMessage" @click="onCard").wrap-item-inbox-card.py8
    div(v-if="user").card-content
      div.profile.f.fm.mb8
        v-avatar(size="36px").mr10
          img(:src="user.iconURL")
        div.profile-info
          div.wrap-name.f.flex-between
            span.name.line-clamp-1.mr4 {{user.name}}
            span.updated-time {{updatedTime}}
          span.latest-message.line-clamp-1 {{latestMessage.message}}
</template>

<style lang="scss" scoped>
.wrap-item-inbox-card {
  background: #fff;
  border-bottom: solid rgba(0, 0, 0, 0.4) 0.5px;
  .card-content {
    width: 93%;
    max-width: 820px;
    margin: 0 auto;
    .profile {
      width: 100%;
      .profile-info {
        width: 100%;
        .name {
          display: block;
          color: #2a2a2a;
        }
        .wrap-name {
          width: 100%;
        }
        .updated-time {
          font-size: 12px;
          color: #999;
        }
        .latest-message {
          font-size: 12px;
          color: #999;
        }
      }
    }
    .latest-message {
      color: #999;
    }
  }
}
</style>

<script>
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: null,
      latestMessage: null
    }
  },
  watch: {
    content () {
      this.init()
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.content.authedClientUid === this.uid) {
        // trainee
        var ref = db.collection('TRAINERS').doc(this.content.createdBy)
      } else {
        // trainer
        var ref = db.collection('CLIENTS').doc(this.content.clientId)
      }
      this.user = await ref.get().then(d => { return d.data() })

      this.latestMessage = await db.collection('GROUPS')
        .doc(this.content.id)
        .collection('MESSAGES')
        .orderBy('created', 'desc')
        .get()
        .then(q => {
          return q.docs.map(d => {
            return d.data()
          })[0]
        })

      this.updatedTime = format(this.latestMessage.created.toDate(), 'yyyy-MM-dd hh:mm', { locale: ja })
    },
    onCard () {
      var path = (this.$route.name === 'inbox') ? `/inbox/${this.content.id}` : `/client_inbox/${this.content.id}`
      this.$router.push(path)
    }
  }
}
</script>
